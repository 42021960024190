import { AxiosError } from "axios"

const getError = (err:any):{message:string} => {
    let errorObj = {
        message:""
    }
    if(err instanceof AxiosError){
        errorObj.message = (err.response?.data?.message) || "Error Occured"; 
    }
    return errorObj;
}

export {
    getError
}