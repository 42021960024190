import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Select, message } from 'antd';
import * as UserServices from '../../services/user.services';
import { AxiosError } from 'axios';
import { types } from '@proj/static';
const { Option } = Select;



type FieldType = {
    name?: string;
    permissions?: string;
};

interface IProps {
    selectedUserRole?:types.userTypes.IUserRole
    onSuccess?:()=>void
}

function AddUserRolesModal(props:IProps) {

    const [isSubmitted,setIsSubmitted] = useState<boolean>(false);
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        setIsSubmitted(true);
        if(props.selectedUserRole && props.selectedUserRole._id){
            UserServices.updateUserRole(props.selectedUserRole._id,values).then(()=>{
                form.resetFields();
                props.onSuccess && props.onSuccess();
                setIsSubmitted(false);
            }).catch((err:AxiosError)=>{
                message.error("Error Occured!");
                setIsSubmitted(false);
            })
        }
        else{
            UserServices.createUserRole(values).then(()=>{
                form.resetFields();
                props.onSuccess && props.onSuccess();
                setIsSubmitted(false);
            }).catch((err:AxiosError)=>{
                message.error("Error Occured!");
                setIsSubmitted(false);
            })
        }
    };

    useEffect(()=>{
        form.resetFields();
        if(props.selectedUserRole){
            form.setFieldsValue(props.selectedUserRole)
        }
    },[props.selectedUserRole,form])

    return (
        <Form
            name="basic"
            form={form}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item<FieldType>
                className="form-box"
                label="Role Name"
                name="name"
                rules={[{ required: true, message: 'Please input your role name!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item<FieldType>
                className="form-box"
                label="Permissions"
                name="permissions"
                rules={[{ required: false, message: 'Please input your clients!' }]}
            >
                <Select
                    allowClear
                    mode="multiple"
                >
                    <Option value="superadmin">Superadmin</Option>
                    <Option value="admin">Admin</Option>
                    <Option value="create-order">Create Order</Option>
                    <Option value="purchase-order">Purchase Order</Option>

                </Select>
            </Form.Item>

            <Form.Item className="modal-btn">
                {
                    props.selectedUserRole?
                    <Button 
                        htmlType="submit" 
                        className="primarybtn"
                        disabled={isSubmitted}
                    >
                    {
                        isSubmitted?
                        'Updating...':'Update'
                    }
                    </Button>
                    :
                    <Button 
                        htmlType="submit" 
                        className="primarybtn"
                        disabled={isSubmitted}
                    >
                    {
                        isSubmitted?
                        'Adding...':'Add'
                    }
                    </Button>
                }
            </Form.Item>
        </Form>
    )
}

export default AddUserRolesModal;
