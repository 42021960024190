import { createSlice,PayloadAction } from "@reduxjs/toolkit";
import {types} from '@proj/static';

type userState = {
    users:types.userTypes.IUser[],
    isLoading:boolean,
    userRoles:types.userTypes.IUserRole[],
    getUserRolesError?:types.commonTypes.HTTP_ERROR
}

const defaultState:userState = {
    users:[],
    isLoading:true,
    userRoles:[]
}


export const UserSlice = createSlice({
    name:"user",
    initialState:defaultState,
    reducers:{
        getUsers:(state)=>{
            return {
                ...state,
                isLoading:true
            }
        },
        getUsersSuccess:(state,action:PayloadAction<types.userTypes.IUser[]>)=>{
            return {
                ...state,
                users:action.payload
            }
        },
        getUsersFailed:(state,action:PayloadAction<types.commonTypes.HTTP_ERROR>) => {
            return {
                ...state,
                getUsersError:action.payload
            }
        },
        getUserRoles:(state)=>{
            return {
                ...state
            }
        },
        getUserRolesSuccess:(state,action:PayloadAction<types.userTypes.IUserRole[]>) => {
            return {
                ...state,
                userRoles:action.payload
            }
        },
        getUserRolesFailed:(state,action:PayloadAction<types.commonTypes.HTTP_ERROR>) => {
            return {
                ...state,
                getUserRolesError:action.payload
            }
        }
     }
})

export const {
    getUsers,
    getUsersSuccess,
    getUsersFailed,
    getUserRoles,
    getUserRolesSuccess,
    getUserRolesFailed
} = UserSlice.actions;

export default UserSlice.reducer;