import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga'

// slices or reducers
import auth from './slices/auth.slice';
import user from './slices/user.slice';
import client from './slices/client.slice';
import Configuration from './slices/configuration.slice';
import Order from './slices/order.slice';
import Common from './slices/common.slice';

// sagas
import authSaga from './sagas/auth.saga';
import userSaga from './sagas/user.saga';
import clientSaga from './sagas/client.saga';
import ConfigurationSaga from './sagas/configuration.saga';
import OrderSaga from './sagas/order.saga';


const saga = createSagaMiddleware();
export const store = configureStore({
    reducer: {
        auth:auth,
        user:user,
        client:client,
        Configuration:Configuration,
        order:Order,
        common:Common
    },
    middleware:[saga]
})

saga.run(authSaga);
saga.run(userSaga);
saga.run(clientSaga);
saga.run(ConfigurationSaga);
saga.run(OrderSaga);



export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch