import { takeEvery,put } from "redux-saga/effects";
import * as User from '../slices/user.slice';
import {types} from '@proj/static';
import * as userServices from '../../services/user.services';

function* getUsers(){
    try{
        let result:types.userTypes.IUser[] = yield(userServices.getUsers());
        yield put(User.getUsersSuccess(result));
    }
    catch(err){
        yield put(User.getUsersFailed({
            error:err,
            message:"Error Occured"
        }))
    }
}

function* getUserRoles(){
    try{
        let result:types.userTypes.IUserRole[] = yield(userServices.getUserRoles());
        yield put(User.getUserRolesSuccess(result));
    }
    catch(err){
        yield put(User.getUserRolesFailed({
            error:err,
            message:"Error Occured"
        }))
    }
}


function* userSaga(){
    yield takeEvery(User.getUsers.type,getUsers);
    yield takeEvery(User.getUserRoles.type,getUserRoles);
}

export default userSaga;