import { types } from "@proj/static";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";

type TInitialState = {
    clients:types.clientTypes.IClientWithCount[],
    clientId:string|null,
    shippingProviders:types.clientTypes.IClientShippingProvider[],
    shippingBoxes:types.clientTypes.IClientShippingBox[]
}

const intialState:TInitialState = {
    clients:[],
    clientId:null,
    shippingProviders:[],
    shippingBoxes:[]
}

export const ClientSlice = createSlice({
    name:"Client",
    initialState:intialState,
    reducers:{
        getClients:(state)=>{
            return {
                ...state
            }
        },
        getClientsSuccess:(state,action:PayloadAction<types.clientTypes.IClientWithCount[]>)=>{
            return {
                ...state,
                clients:action.payload
            }
        },
        getClientsFailed:(state,action:PayloadAction<types.commonTypes.HTTP_ERROR>)=>{
            return {
                ...state
            }
        },

        getClientShippingProviders:(state,action:PayloadAction<string>)=>{
            return {
                ...state,
                clientId:action.payload
            }
        },
        getClientShippingProvidersSuccess:(state,action:PayloadAction<types.clientTypes.IClientShippingProvider[]>) => {
            return {
                ...state,
                shippingProviders:action.payload
            }
        },
        getClientShippingProvidersFailed:(state,action:PayloadAction<types.commonTypes.HTTP_ERROR>)=>{
            return {
                ...state
            }
        },
        getClientShippingBoxes:(state,action:PayloadAction<string>)=>{
            return {
                ...state,
                clientId:action.payload
            }
        },
        getClientShippingBoxesSuccess:(state,action:PayloadAction<types.clientTypes.IClientShippingBox[]>) => {
            return {
                ...state,
                shippingBoxes:action.payload
            }
        },
        getClientShippingBoxesFailed:(state,action:PayloadAction<types.commonTypes.HTTP_ERROR>)=>{
            return {
                ...state
            }
        },
    }
})

export const {
    getClients,
    getClientsSuccess,
    getClientsFailed,
    getClientShippingProviders,
    getClientShippingProvidersSuccess,
    getClientShippingProvidersFailed,
    getClientShippingBoxes,
    getClientShippingBoxesSuccess,
    getClientShippingBoxesFailed
} = ClientSlice.actions;
export default ClientSlice.reducer;