import { takeEvery,put } from "redux-saga/effects";
import * as Auth from '../slices/auth.slice';
import {types} from '@proj/static';
import * as authServices from '../../services/authServices';

function* loginUser(action:types.commonTypes.SAGA_ACTION){
    try{
        let res:types.authTypes.loginSuccessResponse = yield(authServices.login(action.payload));
        if(res && res.token){
            yield put(Auth.onLoginSuccess(res))
        }
    }
    catch(err:any){
        yield put(Auth.onLoginFail(err.response.data))
    }
}

function* checkLogin(action:types.commonTypes.SAGA_ACTION){
    try{
        let res:types.authTypes.ICheckLoginResponse = yield(authServices.checkEmail(action.payload));
        yield put(Auth.checkEmailSuccess(res))
    }
    catch(err:any){
        yield put(Auth.onLoginFail(err.response.data));
    }
}

function* getUserDetails(){
    try{
        let res:types.authTypes.IGetUserDetailsResponse = yield(authServices.getUserDetails());
        yield put(Auth.getUserDetailsSuccess(res))
    }
    catch(err:any){
        //yield put(Auth.onLoginFail(err.response.data));
    }
}


function* authSaga(){
    yield takeEvery(Auth.loginUser.type,loginUser);
    yield takeEvery(Auth.checkEmail.type, checkLogin);
    yield takeEvery(Auth.getUserDetails.type, getUserDetails);
}

export default authSaga;