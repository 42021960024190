import { types } from "@proj/static";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Modal } from "antd";


type TClient = {
    _id:string,
    name:string
}
interface InitialState {
    email:string
    loginErrMsg:string
    isLoggingIn:boolean
    isLoginSuccess:boolean
    isLoggedIn:boolean,
    isEmailChecked?:boolean,
    userClients:TClient[],
    superadmin:boolean,
    userDetails?:{
        selectedClientId?:string,
        permissions:string[]
    }
}

const initialState:InitialState = {
    email:'',
    loginErrMsg:'',
    isLoggingIn:false,
    isLoginSuccess:false,
    isLoggedIn:true,
    isEmailChecked:false,
    userClients:[],
    superadmin:false,
    userDetails:undefined
}

interface LoginPayload {
    email:string
    password:string
}

interface ICheckLoginPayload {
    email:string
}

interface LoginSuccessResponse {
    token:string
    refresh_token:string
}


export const AuthSlice = createSlice({
    name:"auth",
    initialState,
    reducers:{
        isLoggedIn:(state) =>{
            let token = localStorage.getItem('token');
            if(token && token !== ""){
               return {
                ...state,
                isLoginSuccess:true,
                isLoggedIn:true
               } 
            }
            else{
                return {
                    ...state,
                    isLoginSuccess:true,
                    isLoggedIn:false
                   } 
            }
        },

        logoutUser:(state)=>{
            localStorage.removeItem("token");
            return {
                ...state,
                isLoggedIn:false,
                isLoggingIn:false,
                isEmailChecked:false,
                userClients:[],
                isLoginSuccess:false
            }
        },

        loginUser:(state,action:PayloadAction<LoginPayload>) => {
            return {
                ...state,
                isLoggingIn:true,
                loginErrMsg:''
            }
        },

        onLoginFail:(state,action:PayloadAction<types.commonTypes.HTTP_ERROR>) => {
            Modal.error({
                title:(action.payload && action.payload.message) || "An Error Occured"
            })
            localStorage.removeItem("token");
            return {
                ...state,
                isLoggingIn:false,
                loginErrMsg:(action.payload && action.payload.message) || "Unknown Error Occured",
                isLoggedIn:false,
                isLoginSuccess:false
            }
        },

        onLoginSuccess:(state,action:PayloadAction<LoginSuccessResponse>) => {
            localStorage.setItem('token',action.payload.token);
            return {
                ...state,
                isLoginSuccess:true,
                isLoggedIn:true,
                isEmailChecked:false
            }
        },

        onLogout:(state) => {
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
            return {
                ...state,
                isLoginSuccess:false,
                isLoggedIn:false
            }
        },

        checkEmail:(state,action:PayloadAction<ICheckLoginPayload>) => {
            return {
                ...state,
                isLoggingIn:true,
                isEmailChecked:false,
            }
        },

        checkEmailSuccess:(state,action:PayloadAction<types.authTypes.ICheckLoginResponse>) => {
            return {
                ...state,
                isEmailChecked:true,
                isLoggingIn:false,
                userClients:action.payload.clients?action.payload.clients:[],
                superadmin:action.payload.superadmin
            }
        },

        resetLogin:(state) => {
            return {
                ...state,
                email:'',
                isEmailChecked:false,
                userClients:[]
            }
        },

        getUserDetails:(state) => {
            return {
                ...state
            }
        },

        getUserDetailsSuccess:(state,action:PayloadAction<types.authTypes.IGetUserDetailsResponse>) => {
            return {
                ...state,
                userDetails:{
                    selectedClientId:action.payload.selectedClientId,
                    permissions:action.payload.permissions
                }
            }
        }
    }
})

export const {
    isLoggedIn,
    loginUser,
    onLoginFail,
    onLoginSuccess,
    onLogout,
    checkEmail,
    checkEmailSuccess,
    resetLogin,
    logoutUser,
    getUserDetails,
    getUserDetailsSuccess
} = AuthSlice.actions;
export default AuthSlice.reducer;