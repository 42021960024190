import React, { useEffect, useState } from 'react'
import { Button, Form, Input, InputNumber, message, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { types } from '@proj/static';
import * as ClientServices from '../../services/client.services';
import { AxiosError } from 'axios';

import type { SelectProps } from 'antd';

const options: SelectProps['options'] = [];


type FieldType = {
    name?: string;
    code?: string;
    methods?: string;
    accountNo?: string;
    marginAmount?: string;
};

interface IProps {
    clientId: string
    selectedShippingProvider?: types.clientTypes.IClientShippingProvider
    onSuccess?: () => void
}

function ShippingProviderForm(props: IProps) {
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [form] = useForm();

    const onFinish = (values: any) => {
        console.log(values);
        setIsSubmitted(true);
        if (props.selectedShippingProvider && props.selectedShippingProvider._id) {
            ClientServices.updateClientShippingProvider(props.clientId, props.selectedShippingProvider._id.toString(), values).then(() => {
                form.resetFields();
                props.onSuccess && props.onSuccess();
                setIsSubmitted(false);
            }).catch((err: AxiosError) => {
                message.error("Error Occured!");
                setIsSubmitted(false);
            })
        }
        else {
            ClientServices.createClientShippingProvider(props.clientId, values).then(() => {
                form.resetFields();
                props.onSuccess && props.onSuccess();
                setIsSubmitted(false);
            }).catch((err: AxiosError) => {
                message.error("Error Occured!");
                setIsSubmitted(false);
            })
        }
    };

    useEffect(() => {
        form.resetFields();
        if (props.selectedShippingProvider) {
            form.setFieldsValue(props.selectedShippingProvider)
        }
    }, [props.selectedShippingProvider, form])

    return (
        <Form
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
        >
            <Form.Item<FieldType>
                className="form-box"
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Provider name is required!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item<FieldType>
                className="form-box"
                label="Code"
                name="code"
                rules={[{ required: true, message: 'Provider code is required!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item<FieldType>
                className="form-box"
                label="Methods"
                name="methods"
                rules={[{ required: true, message: 'Please add at least one method!' }]}
            >
                <Select
                    mode="tags"
                    allowClear
                    style={{ width: '100%' }}
                />
            </Form.Item>

            <Form.Item<FieldType>
                className="form-box"
                label="Account number"
                name="accountNo"
            >
                <Input />
            </Form.Item>

            <Form.Item<FieldType>
                className="form-box"
                label="Margin amount"
                name="marginAmount"
            >
                <InputNumber />
            </Form.Item>

            <Form.Item className="modal-btn">
                {
                    props.selectedShippingProvider ?
                        <Button
                            htmlType="submit"
                            className="primarybtn"
                            disabled={isSubmitted}
                        >
                            {
                                isSubmitted ?
                                    'Updating...' : 'Update'
                            }
                        </Button>
                        :
                        <Button
                            htmlType="submit"
                            className="primarybtn"
                            disabled={isSubmitted}
                        >
                            {
                                isSubmitted ?
                                    'Adding...' : 'Add'
                            }
                        </Button>
                }
            </Form.Item>
        </Form>
    )
}

export default ShippingProviderForm;
