import React, { useCallback, useEffect, useState } from 'react';
import { Button, Space, Table, Modal, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import ShippingBoxForm from '../../components/client/ShippingBoxForm';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useParams } from 'react-router-dom';
import { types } from '@proj/static';
import * as ClientActions from '../../redux/slices/client.slice';
import * as ClientServices from '../../services/client.services';


function ClientsShippingBoxes() {

    const dispatch = useAppDispatch();
    const params = useParams();
    const [selectedShippingBox,setSelectedShippingBox] = useState<types.clientTypes.IClientShippingBox|undefined>();
    const {shippingBoxes} = useAppSelector(state=>state.client);
    const [clientId,setClientId] = useState<string>('');
    const [isAddShippingBoxFormVisible, setAddShippingBoxFormVisible] = useState(false);

    useEffect(()=>{
        let clientId:string|undefined = params.clientId;
        if(clientId && typeof clientId === 'string'){
            setClientId(clientId);
        }
    },[params])

    const getClientShippingBoxes = useCallback(() => {
        dispatch(ClientActions.getClientShippingBoxes(clientId));
    },[clientId, dispatch])

    useEffect(()=>{
        if(clientId !== ""){
            getClientShippingBoxes();   
        }
    },[clientId, getClientShippingBoxes])

   

    const showAddShippingBoxModal = () => {
        setSelectedShippingBox(undefined);
        setAddShippingBoxFormVisible(true);
    };

    const cancelAddShippingBoxModal = () => {
        setAddShippingBoxFormVisible(false);
    };

    const onEdit = (box:types.clientTypes.IClientShippingBox) => {
        setSelectedShippingBox(box);
        setAddShippingBoxFormVisible(true);
    }

    const onDelete = (method:types.clientTypes.IClientShippingBox) => {
        Modal.confirm({
            title:"Are you sure to delete this shipping box?",
            okType:"danger",
            okText:"Yes",
            onOk:()=>{
                if(method._id){
                    ClientServices.deleteClientShippingBox(clientId,method._id.toString()).then(()=>{
                        message.success("Deleted");
                        getClientShippingBoxes();
                    }).catch(()=>{
                        message.error("Error Occured");
                    })
                }
            }
        })
    }

    const columns: ColumnsType<types.clientTypes.IClientShippingBox> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Dimension (LxWxH)',
            dataIndex: 'dimension',
            key: 'dimension',
            render:(_,record)=>{
                return (
                    record.dimension?
                    `${record.dimension.length}x${record.dimension.width}x${record.dimension.height} (${record.dimension.unit})`:''
                )
            }
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
            render:(_,record)=>{
                return (
                    record.weight?
                    `${record.weight.maxWeight} (${record.weight.unit})`:''
                )
            }
        },
        
        {
            title: 'Action',
            key: 'action',
            align: 'right',
            render: (_, record) => (
                <Space size="middle">
                    <Button 
                        className="primarybtn" 
                        onClick={()=>onEdit(record)}
                    >
                            Edit
                    </Button>
                    <Button 
                        className="dangerbtn"
                        onClick={()=>onDelete(record)}
                    >
                        Delete
                    </Button>
                </Space>
            ),
        },
    ];


    return (
        <section className="element-body">
            <div className="heading-block">
                <h2>Shipping Boxes</h2>
                <Button className="primarybtn" onClick={showAddShippingBoxModal}>Add Shipping Box</Button>
            </div>

            <div className="table-element">
                <Table columns={columns} dataSource={shippingBoxes} pagination={false} />
            </div>

            <Modal
                title={`${selectedShippingBox?"Edit":"Add"} Shipping Box`}
                open={isAddShippingBoxFormVisible}
                onCancel={cancelAddShippingBoxModal}
                footer={[]}
                width={650}
                className="base-modal"
            >
                <ShippingBoxForm
                    clientId={clientId}
                    selectedShippingBox={selectedShippingBox}
                    onSuccess={()=>{
                        setSelectedShippingBox(undefined);
                        cancelAddShippingBoxModal();
                        getClientShippingBoxes();
                        Modal.success({
                            title:`Client shipping box has been ${selectedShippingBox?"updated":"created"} successfully`
                        })
                    }}
                />
            </Modal>

        </section>
    )
}

export default ClientsShippingBoxes;
