import React, { Suspense,lazy } from 'react'
import { Routes, Route, useRoutes } from "react-router-dom";
import ClientsShippingProviders from './pages/clients/ClientsShippingProviders';
import ClientsShippingBoxes from './pages/clients/ClientsShippingBoxes';
import UserRoles from './pages/users/UserRoles';
import ProtectedRoute from './ProtectedRoute';

const Layouts = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Login'));
const Logout = lazy(()=> import('./pages/Logout'));
const Clients = lazy(() => import('./pages/clients/Clients'));
const Users = lazy(() => import('./pages/users/Users'));
const SpireAccounts = lazy(() => import('./pages/spire/SpireAccounts'));
const ShippingConfigurations = lazy(() => import('./pages/shipping/ShippingPartnerConfigurations'));
const CreateOrder = lazy(() => import('./pages/order/Order'));

const NoPage = lazy(() => import('./pages/NoPage'));

function Router() {
    return (
        <Suspense>
            <Routes
                
            >   
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<ProtectedRoute />}>
                    <Route path="/admin" element={<Layouts />}>
                        <Route path="logout" element={<Logout />} />
                        <Route path="clients" element={<Clients />} />
                        <Route path="client/:clientId/shipping-providers" element={<ClientsShippingProviders />} />
                        <Route path="client/:clientId/shipping-boxes" element={<ClientsShippingBoxes />} />

                        <Route path="users" element={<Users />} />
                        <Route path="user-roles" element={<UserRoles />} />
                        <Route path="spire-accounts" element={<SpireAccounts />} />
                        <Route path="shipping-configurations" element={<ShippingConfigurations />} />

                        <Route path="create-order" element={<CreateOrder source="create" />} />
                        <Route path="purchase-label" element={<CreateOrder source="purchase" />} />

                        <Route path="*" element={<NoPage />} />
                    </Route>
                </Route>
                <Route path="*" element={<NoPage />} />
            </Routes>
        </Suspense>
    )
}

export default Router;