import http from "../utils/http";

interface LoginPayload {
    username:string
    Password:string
}

interface EmailCheckPayload {
    email:string
}

export const login = async(payload:LoginPayload) => {
    let result = await (await http().post(`/auth/login`,payload)).data;
    return result;
}

export const checkEmail = async(payload:EmailCheckPayload) => {
    let result = await (await http().post(`/auth/check-email`,payload)).data;
    return result.data;
}

export const getUserDetails = async() => {
    let result = await (await http().get(`/user/user`)).data;
    return result.data;
}