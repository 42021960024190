import React, { useEffect, useState } from 'react'
import { Col, Row, Button, Form, Input, Select, message, InputNumber } from 'antd';
import { types } from '@proj/static';
import { useForm } from 'antd/es/form/Form';
import * as ClientServices from '../../services/client.services';
import { AxiosError } from 'axios';

const { Option } = Select;

type FieldType = {
    name: string;
    code: string;
    length: number;
    width: number;
    height: number;
    dimensionUnit: "cm"|"in";
    weight: number;
    weightUnit: "lb"|"kg";
};

interface IProps {
    clientId:string
    selectedShippingBox?:types.clientTypes.IClientShippingBox
    onSuccess?:()=>void
}

function ShippingBoxForm(props:IProps) {
    const [isSubmitted,setIsSubmitted] = useState<boolean>(false);
    const [form] = useForm();

    const onFinish = (values:FieldType) => {
        let formValues = {
            name:values.name,
            code:values.code,
            dimension:{
                length:values.length,
                width:values.width,
                height:values.height,
                unit:values.dimensionUnit
            },
            weight:{
                maxWeight:values.weight,
                unit:values.weightUnit
            }
        }
        setIsSubmitted(true);
        if(props.selectedShippingBox && props.selectedShippingBox._id){
            ClientServices.updateClientShippingBox(props.clientId,props.selectedShippingBox._id.toString(),formValues).then(()=>{
                form.resetFields();
                props.onSuccess && props.onSuccess();
                setIsSubmitted(false);
            }).catch((err:AxiosError)=>{
                message.error("Error Occured!");
                setIsSubmitted(false);
            })
        }
        else{
            ClientServices.createClientShippingBox(props.clientId,formValues).then(()=>{
                form.resetFields();
                props.onSuccess && props.onSuccess();
                setIsSubmitted(false);
            }).catch((err:AxiosError)=>{
                message.error("Error Occured!");
                setIsSubmitted(false);
            })
        }
    };

    useEffect(()=>{
        form.resetFields();
        if(props.selectedShippingBox){
            let fieldValues:FieldType = {
                name:props.selectedShippingBox.name || "",
                code:props.selectedShippingBox.code || "",
                length:(props.selectedShippingBox.dimension && props.selectedShippingBox.dimension.length) || 0,
                width:(props.selectedShippingBox.dimension && props.selectedShippingBox.dimension.width) || 0,
                height:(props.selectedShippingBox.dimension && props.selectedShippingBox.dimension.height) || 0,
                dimensionUnit:(props.selectedShippingBox.dimension && props.selectedShippingBox.dimension.unit) || "in",
                weight:(props.selectedShippingBox.weight && props.selectedShippingBox.weight.maxWeight) || 0,
                weightUnit:(props.selectedShippingBox.weight && props.selectedShippingBox.weight.unit) || "lb",
                
            }
            form.setFieldsValue(fieldValues)
        }
    },[props.selectedShippingBox,form])



    return (
        <Form
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            form={form}
            autoComplete="off"
        >
            <Form.Item<FieldType>
                className="form-box"
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input your name!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item<FieldType>
                className="form-box"
                label="Code"
                name="code"
                rules={[{ required: true, message: 'Please input your code!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item className="form-box mb-0">
                <Row gutter={16}>
                    <Col className="gutter-row" span={6}>
                        <Form.Item<FieldType>
                            label="Length"
                            name="length"
                        >
                            <InputNumber />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={6}>
                        <Form.Item<FieldType>
                            label="Width"
                            name="width"
                        >
                            <InputNumber />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={6}>
                        <Form.Item<FieldType>
                            label="Height"
                            name="height"
                        >
                            <InputNumber />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={6}>
                        <Form.Item<FieldType>
                            label="Dimension Unit"
                            name="dimensionUnit"
                        >
                            <Select
                                allowClear
                            >
                                <Option value="in">inch</Option>
                                <Option value="cm">cm</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

            </Form.Item>

            <Form.Item className="form-box mb-0">
                <Row gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item<FieldType>
                            label="Weight"
                            name="weight"
                        >
                            <InputNumber />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item<FieldType>
                            label="Weight Unit"
                            name="weightUnit"
                        >
                            <Select
                                allowClear
                            >
                                <Option value="lb">lb</Option>
                                <Option value="kg">kg</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

            </Form.Item>

            <Form.Item className="modal-btn">
            {
                props.selectedShippingBox?
                <Button 
                    htmlType="submit" 
                    className="primarybtn"
                    disabled={isSubmitted}
                >
                {
                    isSubmitted?
                    'Updating...':'Update'
                }
                </Button>
                :
                <Button 
                    htmlType="submit" 
                    className="primarybtn"
                    disabled={isSubmitted}
                >
                {
                    isSubmitted?
                    'Adding...':'Add'
                }
                </Button>
            }
            </Form.Item>
        </Form>
    )
}

export default ShippingBoxForm;
