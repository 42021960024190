import { types } from "@proj/static";
import http from "../utils/http";
import { IClientShippingBox, IClientShippingProvider } from "@proj/static/types/client.types";

export const getClients = async() => {
    let result = await (await http().get(`/client`)).data;
    return result.data;
}

export const createClient = async(data:types.clientTypes.IClient) => {
    let result = await (await http().post(`/client`,data)).data;
    return result.data;
}

export const updateClient = async(id:string,data:types.clientTypes.IClient
    ) => {
    let result = await (await http().patch(`client/${id}`,data)).data;
    return result.data;
}

export const deleteClient = async(id:string) => {
    let result = await (await http().delete(`client/${id}`)).data;
    return result.data;
}

export const getClientShippingProviders = async(clientId:string) => {
    let result = await (await http().get(`/client/${clientId}/shipping-providers`)).data;
    return result.data;
}


export const createClientShippingProvider = async(clientId:string,data:types.clientTypes.IClientShippingProvider) => {
    let result = await (await http().post(`/client/${clientId}/shipping-provider`,data)).data;
    return result.data;
}

export const updateClientShippingProvider = async(clientId:string,id:string,data:IClientShippingProvider) => {
    let result = await (await http().patch(`/client/${clientId}/shipping-provider/${id}`,data)).data;
    return result.data;
}

export const deleteClientShippingProvider = async(clientId:string,id:string) => {
    let result = await (await http().delete(`/client/${clientId}/shipping-provider/${id}`)).data;
    return result.data;
}

export const getClientShippingBoxes = async(clientId:string) => {
    let result = await (await http().get(`/client/${clientId}/shipping-boxes`)).data;
    return result.data;
}


export const createClientShippingBox = async(clientId:string,data:types.clientTypes.IClientShippingBox) => {
    console.log(data);
    let result = await (await http().post(`/client/${clientId}/shipping-box`,data)).data;
    return result.data;
}

export const updateClientShippingBox = async(clientId:string,id:string,data:IClientShippingBox) => {
    let result = await (await http().patch(`/client/${clientId}/shipping-box/${id}`,data)).data;
    return result.data;
}

export const deleteClientShippingBox = async(clientId:string,id:string) => {
    let result = await (await http().delete(`/client/${clientId}/shipping-box/${id}`)).data;
    return result.data;
}
