import React, { useEffect, useState } from 'react'
import { Button, Space, Table, Modal, Dropdown, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import UserRoleForm from '../../components/user/UserRoleForm';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getUserRoles } from '../../redux/slices/user.slice';
import { types } from '@proj/static';
import * as UserServices from '../../services/user.services';

interface DataType {
    key: string;
    name: string;
}



function UserRoles() {
    const dispatch = useAppDispatch();
    const [isAddUserRoleModalVisible, setAddUserRoleModalVisible] = useState(false);
    const [selectedUserRole, setSelectedUserRole] = useState<types.userTypes.IUserRole | undefined>();
    const {userRoles} = useAppSelector(state=>state.user);

    useEffect(()=>{
        dispatch(getUserRoles())
    },[dispatch])


    const onAdd = () => {
        setSelectedUserRole(undefined);
        setAddUserRoleModalVisible(true);
    };

    const cancelAddUserRolesModal = () => {
        setAddUserRoleModalVisible(false);
    };

    const onEditRole = (role:types.userTypes.IUserRole) => {
        setSelectedUserRole(role);
        setAddUserRoleModalVisible(true);
    }

    const onDeleteRole = (role:types.userTypes.IUserRole) => {
        

        Modal.confirm({
            title:"Are you sure to delete this user role?",
            okText:"Yes",
            okType:"danger",
            onOk:()=>{
                if(role && role._id){
                    UserServices.deleteUserRole(role._id).then(()=>{
                        dispatch(getUserRoles())
                    }).catch(err=>{
                        message.error(err.toString())
                    })
                }
                else{
                    message.error("Role ID not found!");
                }
            }
        })
        
    }

    const columns: ColumnsType<types.userTypes.IUserRole> = [
        {
            title: 'Role name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Permissions',
            dataIndex: 'permissions',
            key: 'permissions',
            render:(_,record)=>(
                <ul>
                {
                    record.permissions && Array.isArray(record.permissions) && record.permissions.map(permission=>{
                        return <li>{permission}</li>
                    })
                }
                </ul>
            )
        },
        {
            title: 'Action',
            key: 'action',
            align: 'right',
            render: (_, record) => (
                <Space size="middle">
                    <Button className="primarybtn" onClick={()=>onEditRole(record)}>Edit</Button>
                    <Button className="dangerbtn" onClick={()=>onDeleteRole(record)}>Delete</Button>
                </Space>
            ),
        },
    ];

    return (
        <section className="element-body">
            <div className="heading-block">
                <h2>User Roles</h2>
                <Button className="primarybtn" onClick={()=>onAdd()}>Add User Role</Button>
            </div>

            <div className="table-element">
                <Table columns={columns} dataSource={userRoles} pagination={false} />
            </div>

            <Modal
                title={`${selectedUserRole?'Edit':'Add'} User Role`}
                open={isAddUserRoleModalVisible}
                onCancel={cancelAddUserRolesModal}
                footer={[]}
                width={650}
                className="base-modal"
            >
               <UserRoleForm 
                onSuccess={()=>{
                    Modal.success({
                        title:`User Role has been ${selectedUserRole?'updated':'created'} successfully`
                    });
                    setAddUserRoleModalVisible(false);
                    dispatch(getUserRoles())
                }}
                selectedUserRole={selectedUserRole}
               />
            </Modal>

        </section>
    )
}

export default UserRoles;
